import React, { useEffect } from "react";
import { render } from "react-dom";
import { observe } from "selector-observer";
import { Karousel } from "../../../../scripts/react/Carousels";
import { DefaultClasses } from "../../../../scripts/react/Carousels/defaults";

export const HeroCarousel = (props) => {
    const {
        title,
        description,
        ctaLabel,
        ctaUrl,
        ctaTarget,
        imageUrl,
        imageAlt
    } = props;

    return <div className="hero-carousel">
        <div className="hero-content">
            <section className="hero">
                <div className="hero__image">
                    <a title={ctaUrl} aria-label={ctaUrl} href={ctaUrl} target={ctaTarget}>
                    <div className="image" >
                            <img alt={ctaUrl} src={imageUrl} />
                    </div>
                    </a>
                </div>
                <div className="hero__cta">
                    {title && <h2>{title}</h2>}
                    <p dangerouslySetInnerHTML={{ __html: description }}></p>
                </div>
             </section>
        </div>
    </div>
}

const getKarouselOptions = (length) => {
    return {
        buttons: length > 1,
        classes: DefaultClasses,
        draggable: length > 1,
        indicators: length > 1,
        responsive: [
            {
                breakpoint: 1200,
                options: {
                    slidesToShow: 1,
                    indicators: length > 1,
                    buttons: length > 1,
                    draggable: true,
                }
            }, {
                breakpoint: 768,
                options: {
                    slidesToShow: 1,
                    indicators: length > 1,
                    buttons: length > 1,
                    draggable: true,
                }
            }
        ],
        slidesToScroll: 1,
    };
}

export const HeroCarouselItems = ({ disableAnimation, cards }) => {
    if (!cards?.length) return null;
    const karouselOptions = getKarouselOptions(cards.length);
    return (
        <Karousel animationDisabled={disableAnimation} {...karouselOptions}>
            {cards.map((card, index) => (
                <HeroCarousel {...card} key={index} />
            ))}
        </Karousel>
    );
};

 document.addEventListener("readystatechange", () => {
     if (document.readyState === "interactive") {
         observe('[data-component="heroCarouselItems"]', {
             add(element) {
                 const { dataset } = element as HTMLElement;
                 const { propsId } = dataset;
                 const componentData = window.ucsdh[`heroCarouselItems_${propsId}`];
                 render(<HeroCarouselItems {...componentData} />, element);
             },
         });
     }
 });
